import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
  ProgressStep,
  ProgressStepper,
} from '@havenengineering/module-owners-shared-library/dist/components/ProgressStepper';
import { LoadingIndicator } from '@havenengineering/module-shared-library/dist/components/LoadingIndicator';

import { fetchWrapper, withApiBaseUrl } from '../../helpers/fetch';
import { DirectDebitBalanceType } from '../../types/finance/directDebit/directDebit';
import { LettingConfigEntry } from '../../types/lettingConfig';
import { displayOneOffPayment } from './helpers';
import styles from './Let2OwnProgressStepper.module.scss';

export enum Let2OwnRenewalSteps {
  ADD_BREAKS = 'addBreaks',
  ONE_OFF_PAYMENT = 'oneOffPayment',
  DIRECT_DEBIT = 'directDebit',
  CONFIRM_DETAILS = 'confirmDetails',
}

type Props = {
  currentStep: Let2OwnRenewalSteps;
  handleClick?: (step: Let2OwnRenewalSteps) => void;
  currentDate: string;
  complete?: boolean;
  skipPayment?: boolean;
};

export const Let2OwnProgressStepper = ({
  currentStep,
  handleClick,
  currentDate,
  complete = false,
  skipPayment = false,
}: Props) => {
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(true);
  const [partialPaymentConfig, setPartialPaymentConfig] = useState<
    LettingConfigEntry[] | undefined
  >([]);

  const onStepClick = (step: Let2OwnRenewalSteps) => {
    switch (step) {
      case Let2OwnRenewalSteps.ADD_BREAKS:
        router.push('/bookings');
        break;
      case Let2OwnRenewalSteps.ONE_OFF_PAYMENT:
        router.push('/finance/partial-payment');
        break;
      case Let2OwnRenewalSteps.DIRECT_DEBIT:
        router.push(
          `/finance/direct-debit/setup?balanceType=${DirectDebitBalanceType.Balance}`
        );
        break;
    }
  };

  const fetchLettingConfig = async () => {
    try {
      const config = await fetchWrapper(
        withApiBaseUrl(
          '/letting/letting-config?name=let2ownPartialPaymentDateRange'
        ),
        {
          method: 'GET',
          credentials: 'include',
        }
      );
      setPartialPaymentConfig(config);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLettingConfig();
  }, []);

  const RENEWAL_PROGRESS_STEPS = [
    {
      label: 'Add breaks',
      step: Let2OwnRenewalSteps.ADD_BREAKS,
    },
    ...(displayOneOffPayment(partialPaymentConfig, currentDate) && !skipPayment
      ? [
          {
            label: 'One-off payment',
            step: Let2OwnRenewalSteps.ONE_OFF_PAYMENT,
          },
        ]
      : []),
    ...(!skipPayment
      ? [
          {
            label: 'Direct Debit',
            step: Let2OwnRenewalSteps.DIRECT_DEBIT,
          },
        ]
      : []),
    {
      label: 'Confirm details',
      step: Let2OwnRenewalSteps.CONFIRM_DETAILS,
    },
  ] as ProgressStep<Let2OwnRenewalSteps>[];

  if (loading) {
    return (
      <div className={styles.loading}>
        <LoadingIndicator loading />
      </div>
    );
  }

  return (
    <div className={styles.renewalStepper}>
      <ProgressStepper
        title="Let2offset renewal"
        steps={RENEWAL_PROGRESS_STEPS}
        currentStep={currentStep}
        handleClick={handleClick ?? onStepClick}
        complete={complete}
      />
    </div>
  );
};
